import { countryCodeToCountry } from "src/data/countries/countryUtil"
import { THomeAddress } from "src/data/homes/types/homeTypes"

export function formatStreetname(address: Partial<THomeAddress>) {
  return [address.street_name1, address.street_name2]
    .filter(Boolean)
    .filter((i) => i && i.length > 0)
    .join(", ")
}

export function formatPostalAddress(address: Partial<THomeAddress>) {
  return [
    address.post_code,
    address.city,
    address.region,
    address.country && countryCodeToCountry(address.country),
  ]
    .filter(Boolean)
    .filter((i) => i && i.length > 0)
    .join(", ")
}
